.preview {
  position: relative;
  border: 2px solid transparent;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  &:hover {
    .preview-hover {
      opacity: 1;
      visibility: visible;
    }
  }
  &.preview--red {
    border-color: #E26A76;
    .preview-desc {
      background: #E26A76;
      display: block;
    }
  }
  &.preview--blue {
    border-color: #4A7FB2;
    .preview-desc {
      background: #4A7FB2;
      display: block;
    }
  }
  &.preview-cart {
    .quantity {
      display: flex;
    }
    .preview-icon-cart {
      display: none;
    }
  }
  &.preview-reminder {
    .preview-reminder-on {
      display: none;
    }
    .preview-reminder-off {
      display: block;
    }
  }
  &-reminder-off {
    display: none;
    width: 20px;
    height: 20px;
  }
  &-reminder-on {
    display: block;
    width: 21px;
    height: 21px;
  }
  &-svg {
    color: @color-1;
  }
  &-desc {
    z-index: 1;
    width: 100%;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  &-btn {
    max-width: 80%;
    width: 100%;
    &+& {
      margin-top: 15px;
    }
  }
  &-caption {
    color: #333;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 10px;
  }
  &-info {
    color: #666;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
  }
  &-wrap {
    position: relative;
    display: flex;
    height: 200px;
    margin-bottom: 15px;
  }
  &-hover {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    visibility: hidden;
    top: 0;
    bottom: 0;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s, visibility 0.2s;
  }
  &-img {
    margin: auto;
    width: 155px;
    height: 155px;
    object-fit: contain;
  }
  &-second {
    padding: 10px;
    flex-grow: 1;
  }
  &-bottom {
    padding: 10px;
  }
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 -5px;
  }
  &-icon {
    color: @color-1;
  }
  &-col {
    padding: 5px;
  }
  &-price {
    font-size: 20px;
  }
  .product-status {
    font-size: 16px;
  }
  .quantity {
    display: none;
  }
}
