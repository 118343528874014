@font-face {
  font-family: @icon-font-name;
  src: url('@{icon-font-path}/@{icon-font-name}.eot');
  src: url('@{icon-font-path}/@{icon-font-name}.eot?#iefix') format('embedded-opentype'),
  url('@{icon-font-path}/@{icon-font-name}.woff2') format('woff2'),
  url('@{icon-font-path}/@{icon-font-name}.woff') format('woff'),
  url('@{icon-font-path}/@{icon-font-name}.ttf') format('truetype'),
  url('@{icon-font-path}/@{icon-font-name}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
.font-icons {
  position: relative;
  display: inline-block;
  &:before, &:after {
    font-family: @icon-font-name;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: inherit;
    width: auto;
    line-height: 1em;
  }
}
.icon-check:before { content: '\e800'; } /* '' */
.icon-close:before { content: '\e801'; } /* '' */
.icon-eye:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-cart:before { content: '\e804'; } /* '' */
.icon-menu:before { content: '\e805'; } /* '' */
.icon-star:before { content: '\e806'; } /* '' */
.icon-chevron-left:before { content: '\e807'; } /* '' */
.icon-chevron-right:before { content: '\e808'; } /* '' */
.icon-trash:before { content: '\e809'; } /* '' */
.icon-clock:before { content: '\e80b'; } /* '' */
.icon-letter:before { content: '\e80c'; } /* '' */
.icon-phone:before { content: '\e80d'; } /* '' */
.icon-cart2:before { content: '\e810'; } /* '' */
@font-face {
  font-family: Gotham Rounded;
  src: url('@{font-path}gotham-rounded/GothamRounded-Light.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Gotham Rounded;
  src: url('@{font-path}gotham-rounded/GothamRounded-Book.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Gotham Rounded;
  src: url('@{font-path}gotham-rounded/GothamRounded-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Gotham Rounded;
  src: url('@{font-path}gotham-rounded/GothamRounded-Bold.otf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Lucida Grande;
  src: url('@{font-path}lucida-grande/LucidaGrande.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Lucida Grande;
  src: url('@{font-path}lucida-grande/LucidaGrandeBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap&subset=cyrillic');
