.notification-page{
	display:flex;
	align-items: center;
	flex-direction: column;
    justify-content: center;
}
.balance {
  background: #fff;
  padding: 10px 25px;
  position: absolute;
  right: 0;
  top: 155px;
  color: @color-1;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
  max-width: 470px;
  box-sizing: border-box;
  &-ttl {
    font-size: 16px;
    display: block;
  }
  &-count {
    font-size: 28px;
    display: block;
    font-weight: 600;
  }
  &-outer {
    display: flex;
    margin: 0 -15px;
  }
  &-itm {
    padding: 0 15px;
    width: 50%;
  }
}
.description {
  background: #fff;
  padding: 80px 0;
  &-img {
    margin: 0 auto 30px;
    height:auto;
    max-width: 500px;
  }
  &-mobile {
    display: none;
  }
}
.home-page {
  padding: 0;
}
.sign-up-page {
  padding: 0;
}
.categories-block {
  .heading {
    padding: 18px 20px;
    background: #80B2D6;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    &-filter {
      display: none;
    }
    .font-icons {
      margin-right: 14px;
    }
  }
  .single-item {
    display: block;
    padding: 12px 20px;
    font-weight: 500;
    a {
      text-decoration: none;
      border-bottom: 2px solid transparent;
      color: @color-0;
    }
    &.active {
      a {
        color: @color-1;
        border-color: @color-1;
      }
    }
    &:hover {
      a {
        border-color: @color-1;
      }
    }
  }
  .items-holder {
    padding: 20px 0;
    background: #fff;
  }
}
.limit {
  &-outer {
    margin-bottom: 20px;
  }
  &-ttl {
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    color: #fff;
    padding: 5px;
    text-align: center;
    width: 265px;
    margin-bottom:10px;
    &--red {
      background: #E26A76;
    }
    &--blue {
      background: #4A7FB2;
    }
  }
  &-info {
    font-size: 16px;
  }
  .bt {
    height: auto!important;
    min-height: auto;
    margin: 0 auto;
  }
}
.product-limit {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  &.product-limit--red {
    .product-limit-ttl {
      background: #E26A76;
    }
  }
  &.product-limit--blue {
    .product-limit-ttl {
      background: #4A7FB2;
    }
  }
  &-close {
    position: absolute;
    top: 20px;
    color: #000;
    right: 20px;
  }
  &-itm {
    width: 50%;
  }
  &-ttl {
    text-align: center;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    color: #fff;
    padding: 5px;
  }
  &-popup {
    z-index: 10;
    font-size: 16px;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 20px;
    width: 100%;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    display: none;
    &.show {
      display: block;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      z-index: 2;
      right: 150px;
      top: -9px;
      background: #fff;
      width: 20px;
      height: 20px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.02);
    }
  }
  .link {
    padding: 0 10px;
    text-decoration: none;
  }
}
.link-limit {
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
}
.details {
  &-wrap {
    padding: 30px;
    background: #fff;
    margin-bottom: 25px;
  }
  &-text {
    font-size: 16px;
  }
  .caption-block {
    position: relative;
    font-size: 20px;
    margin-bottom: 30px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 1px;
      background: #E5E5E5;
    }
    span {
      position: relative;
      z-index: 1;
      color: @color-1;
      border-bottom: 2px solid @color-1;
    }
  }
  .options {
    width: 360px;
    flex-shrink: 0;
    .single-option {
      display: flex;
      & + .single-option {
        margin-top: 15px;
      }
      .text {
        margin-right: 5px;
      }
    }
  }
}
.shipping-variant {
  &+& {
    margin-top: 15px;
  }
}
.shipping-price {
  color: @color-1;
  font-size: 20px;
  font-weight: 500;
}
.address-block {
  .caption-block {
    margin-bottom: 40px!important;
  }
  .address-holder {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
  }
}
.single-address {
  padding: 25px 30px;
  border: 1px solid #CCE0EE;
  margin: 15px;
  &.address-shipping {
    padding: 0 0 25px 0;
    width: 100%;
    margin: 0;
    border: none;
    border-bottom: solid 1px #cccccc;
    &+& {
      margin-top: 25px;
    }
  }
  .address-links {
    margin: -9px;
    .column {
      padding: 9px;
      width: auto;
    }
  }
  .address-text {
    margin-bottom: 15px;
  }
  .edit-address {
    font-weight: 500;
  }
}
.information-block {
  .info-items {
    .single-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #979797;
      padding: 15px 0;
      &:last-child {
        border-bottom: none;
      }
      .text {
        margin-right: 15px;
      }
    }
  }
}
.contacts-info {
  .info-block {
    display: flex;
    & + .info-block {
      margin-top: 24px;
    }
  }
  .icon {
    margin-right: 5px;
    .size(30px);
    background: #CCE0EE;
    border-radius: 1000px;
    color: @color-1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 14px;
  }
  .text {
    font-size: 20px;
    font-weight: 500;
  }
}
.authorization-img {
  display: none;
}
.heading-caption-mobile {
  display: none;
}
.account-info {
  display: none;
}
.column-mobile {
  display: none!important;
}
.header-search,
.categories-link,
.search-reset,
.search-close{
  display: none;
}
.header {
  &-lang {
    margin: 0 15px;
    display: inline-block;
    vertical-align: middle;
  }
}
.link-outer {
  text-align: center;
  margin: 20px 0 0;
  .link {
    display: block;
    margin-bottom: 5px;
  }
}
.content-block {
  position: relative;
}
.spinner {
  background: rgba(255,255,255,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  z-index: 2;
  &-icon {
    margin: auto;
    display: block;
    fill: #0064AC;
    width: 25px;
    height: 25px;
    animation: spin 1.2s linear infinite;
  }
  &.spinner-fixed {
    position: static;
    background: none;
  }
}
.logo-hpp {
  margin: 0 auto 15px;
  display: block;
  max-width: 275px;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.flex-outer {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  margin: 0 -20px;
  &.flex-outer-confirm {
    align-items: center;
    margin: 0 -10px;
    .flex-row {
      padding: 0 10px;
    }
  }
  &.flex-outer-desc {
    align-items: center;
  }
  &.flex-outer-form {
    margin: 0;
    .flex-row {
      padding: 0 60px;
      &:first-child {
        border-right: 1px solid #979797;
      }
    }
  }
}
.flex-row {
  width: 50%;
  padding: 0 20px;
}
.brand-color-dark {
  color: @color-1;
}
.block-quick {
  display: none;
  position: relative;
  &.show {
    display: block;
  }
}
.quick-icon {
  color: #333333;
  position: absolute;
  top: 20px;
  right: 20px;
}
.quick-close {
  display: none;
}
.img {
  max-width: 100%;
  display: block;
  margin: 0 auto 30px;
}
.contacts-form {
  max-width: 450px;
}
.header-logo {
  width: 195px;
}
.list-nav {
  padding: 20px;
  background: #fff;
  display: flex;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index:2;
  &-li {
    list-style: none;
  }
  &-link {
    color: #333333;
    font-size: 20px;
    text-decoration: none;
    border-bottom: 4px solid transparent;
    transition: border-color 0.2s, color 0.2s;
    padding: 5px 20px;
    &:hover {
      color: @color-1;
      border-color:@color-1;
    }
    &.list-nav-first {
      padding-left: 0;
    }
    &.list-nav-active {
      color: @color-1;
      border-color:@color-1;
    }
  }
}
.catalog {
  &-outer {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
  }
  &-itm {
    width: 33.3%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}