@media only screen and (max-width: 1200px) {
  footer {
    .menu-holder {
      .caption {
        font-size: 16px;
      }
      .menu-item {
        padding: 0 15px;
      }
    }
  }
  .catalog {
    &-itm {
      width: 50%;
    }
  }
}
