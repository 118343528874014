@media only screen and (max-width: 480px) {
  .bt {
    width: 100%;
    &.s1 {
      width: 100%;
    }
    &.bt-close {
      width: 100%!important;
      box-sizing: border-box;
    }
  }
  .popup-window {
    .buttons-holder {
      flex-direction: column;
      & > [class*="bt"] {
        margin: 0 15px 15px 15px!important;
        width: calc(100% - 30px)!important;
      }
    }
  }
  .heading-caption {
    font-size: 22px;
  }
  .dropdown {
    &.dropdown-fixed {
      display: block;
    }
  }
  .catalog {
    &-itm {
      width: 100%;
    }
  }
  .card {
    &-btn {
      display: block!important;
    }
  }
}