.step {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  max-width: 820px;
  width: 100%;
  position: relative;
  &-itm {
    width: 33.3%;
    text-align: center;
    padding: 0 40px;
    position: relative;
    &:first-child {
      &:after{
        display: none;
      }
    }
    &:last-child {
      &:before{
        display: none;
      }
    }
    &:after,
    &:before{
      position: absolute;
      top: 18px;
      width: 40px;
      height: 1px;
      background: #0064AC;
      content: '';
    }
    &:after {
      left: 0;
    }
    &:before {
      right: 0;
    }
  }
  &-done {
    .step-icon {
      font-size: 0;
      border-color: #0064AC;
      background: #0064AC;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(-45deg);
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        height: 8px;
        width: 14px;
      }
    }
  }
  &-active {
    .step-icon {
      font-size: 0;
      border-color: #0064AC;
      &:after {
        border-radius: 50%;
        background: #0064AC;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 27px;
        height: 27px;
      }
    }
  }
  &-icon {
    display: block;
    margin: 0 auto 7px;
    border-radius: 50%;
    border: 1px solid #999999;
    width: 36px;
    height: 36px;
    position: relative;
    color: #0064AC;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
  }
  &-text {
    color: #0064AC;
    font-size: 16px;
  }
}