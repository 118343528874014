.popup-window {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 99;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .popup-information {
    white-space: nowrap;
    margin-right: 20px;
  }
  &.popup-window-view {
    .popup-block {
      max-width:1200px;
      margin: 0 20px;
    }
    .heading-caption {
      margin-bottom: 25px;
    }
    .card {
      margin-bottom: 0;
    }
  }
  &.popup-window-product {
    .btn-full {
      width: 100%!important;
    }
    .table-order {
      margin-bottom: 0;
    }
    .columns-flex {
      &.align-right {
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
  .heading-caption {
    margin-bottom: 0;
  }
  .backdrop {
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.20);
  }
  .popup-wrap {
    margin-bottom: 20px;
  }
  .popup-block {
    position: relative;
    z-index: 1;
    background: #fff;
    max-width: 750px;
    padding: 30px;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    .heading-block {
      margin-bottom: 20px;
      .columns-flex {
        justify-content: space-between;
      }
    }
    .close {
      .size(40px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .font-icon {
        font-size: 20px;
      }
      &.close-bt {
        max-width: 240px;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }
    .field {
      max-width: 100%;
      &.s2 {
        width: 430px;
      }
      &.s1 {
        width: 230px;
      }
    }
    .buttons-holder {
      margin: 25px -15px -15px -15px;
      justify-content: flex-start;
      &>[class*="bt"] {
        width: 232px;
        max-width: 100%;
        margin: 15px;
      }
    }
  }
}
.popup-center {
  text-align: center;
  .buttons-holder {
    justify-content: center!important;
  }
  .bt {
    margin: 0 auto;
  }
}
.popup-text {
  margin-bottom: 15px;
}
.popup-img {
  max-width: 100%;
  display: block;
  margin: 0 auto 30px;
}