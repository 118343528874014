.unsubscribe {
  background: #fff;
  padding: 30px;
  max-width: 430px;
  margin: 0 auto;
  text-align: center;
  &-outer {
    display: flex;
    margin: 0 -10px;
  }
  &-itm {
    padding: 0 10px;
    width: 100%;
  }
  &-img {
    width: 160px;
    height: 130px;
    display: block;
    margin: 0 auto 20px;
  }
  &-text {
    margin-bottom: 20px;
  }
  &-info {
    font-weight: 600;
    color: #333333;
    font-size: 30px;
    margin-bottom: 20px;
  }
  &-wrap {
    margin-bottom: 20px;
  }
  .bt,
  .bt-white {
    width: 100%;
    min-width: auto;
  }
}