.field {
  margin-bottom: 20px;
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  color: @color-0;
  font-family: Gotham Rounded, sans-serif;
  input[type="text"], input[type="email"], input[type="date"], input[type="datetime"], input[type="time"], input[type="password"], input[type="search"], textarea, select {
    border: none;
    background: none;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    box-shadow: none !important;
    height: 46px;
    padding: 10px;
    display: flex;
    font-size: inherit;
    flex-grow: 1;
    color: @color-0;
    outline: none;
    .placeholder(@color-3);
    &:focus, &:active {
      &:not(:disabled){
        box-shadow: 0 0 7px 3px #eef3fd;
      }
    }
  }
  textarea {
    height: 100px;
  }
  .input-block {
    border: 2px solid #333;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #ffffff;
    transition: 0.3s ease-out all;
    &:after {
      font-family: @icon-font-name;
      font-style: normal;
      font-weight: normal;
      speak: none;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      .size(25px);
      font-size: 10px;
      line-height: 100%;
      border-radius: 1000px;
      overflow: hidden;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-right: 10px;
    }
    input {
      &::placeholder {
        color: #575757;
      }
    }
    .date {
      text-transform: uppercase;
    }
    .show-password {
      cursor: pointer;
      font-size: 15px;
      color: #CCCCCC;
      margin-right: 10px;
    }
    &.textarea {
      align-items: flex-start;
    }
  }
  .message {
    display: block;
    font-family: inherit;
    font-weight: 400;
    font-size: 16px;
  }
  label {
    display: block;
    color: @color-0;
    font-weight: 400;
    span {
      font-weight: 400;
      font-size: 16px;
    }
  }
  &.disabled {
    .input-block {
      background: @color-5;
    }
  }
  &.error {
    .input-block {
      border-color: @color-6;
    }
    .message {
      color: @color-6;
    }
  }
  &.success {
    .input-block {
      border-color: @color-7;
      &:after {
        content: '\e800';
        background-color: @color-7;
      }
    }
    .message {
      color: @color-7;
    }
  }
  &.focus {
    .input-block {
      border-color: @color-1;
    }
  }
  &.v2 {
    &.error {
      .message {
        &:before {
          content: '\e801';
          background-color: @color-6;
        }
      }
    }
    &.success {
      .message {
        &:before {
          content: '\e800';
          background-color: @color-7;
        }
      }
    }
    .input-block-holder {
      position: relative;
      width: 100%;
      .input-block {
        &:after, &:before {
          display: none;
        }
      }
      .message {
        display: flex;
        align-items: center;
        padding-top: 8px;
        white-space: nowrap;
        &:before {
          font-family: @icon-font-name;
          font-style: normal;
          font-weight: normal;
          speak: none;
          text-decoration: inherit;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          .size(25px);
          font-size: 9px;
          border-radius: 1000px;
          overflow: hidden;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          margin-right: 5px;
        }
      }
    }
  }
}
.form {
  &.v2 {
    width: 430px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    .buttons-holder {
      &>* {
        flex-grow: 1;
      }
    }
  }
}
.field-group {
  display: flex;
  align-items: flex-start;
  .field-group {
    display: inline-flex;
    align-items: baseline;
  }
  &.inline {
    align-items: center;
    justify-content: space-between;
  }
  &.inline-adaptive {
    .field {
      input, select {
        width: 100%;
      }
    }
    &.v2 {
      margin-left: -5px;
      margin-right: -5px;
      .field {
        margin: 0 5px;
      }
    }
  }
  &.multiple {
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
    display: block;
    .field {
      flex-grow: 1;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      display: inline-block;
      vertical-align: middle;
      .input-block {
        display: inline-block;
        vertical-align: middle;
      }
    }
    &.margin-default {
      margin-top: -10px;
      margin-left: -10px;
      margin-right: -10px;
      .field {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    &.between {
      justify-content: space-between;
    }
  }
}
.radio {
  &-outer {
    display: flex;
  }
  &-itm {
    width: 50%;
  }
  &-btn {
    display: block;
  }
  &-text {
    transition: background-color 0.2s, color 0.2s;
    text-align: center;
    border: 2px solid @color-1;
    padding: 10px;
    font-size: 20px;
    display: block;
    background: transparent;
    font-weight: 400;
    color: @color-4;
  }
  &-inp {
    visibility: hidden;
    position: absolute;
    &:checked ~ .radio-text {
      color: #fff;
      background: @color-1;
    }
  }
}
.checkbox {
  &-label {
    display: flex!important;
    align-items: center;
  }
  &-text {
    color: #333333;
    font-size: 18px;
    display: block;
    user-select: none;
  }
  &-btn {
    width: 25px;
    height: 25px;
    border: 1px solid @color-1;
    background: #fff;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-right: 10px;
    &:after {
      display: none;
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(-45deg);
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      height: 5px;
      width: 11px;
    }
  }
  &-inp {
    visibility: hidden;
    position: absolute;
    &:checked ~ .checkbox-btn {
      color: #fff;
      background: @color-1;
      &:after {
        display: block;
      }
    }
  }
}