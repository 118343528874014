.button-constructor (
  @bt-width: 120px,
  @bt-height: 50px,
  @font-size:20px,
  @bg-color: @color-1,
  @border-color: @bg-color,
  @color:#ffffff,
  @bg-hover-color: @color-4,
  @radius: 3px
) {
  min-width: @bt-width;
  min-height: @bt-height;
  background-color: @bg-color;
  font-size: @font-size;
  font-weight: 400;
  font-family: Gotham Rounded, sans-serif;
  border-radius: @radius;
  box-shadow: 5px 5px 9px rgba(5, 73, 43, 0.18000000715255737);
  transition: 0.3s ease-out all;
  border: 1px solid @border-color;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  text-decoration: none;
  color: @color;
  outline: none;
  cursor: pointer;
  padding: 12px;
  &:not(button) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &:not(.disabled) {
    &:visited {
      outline: none;
    }
    &:hover, &:active, &:focus {
      outline: none;
      color: @color;
    }
    &:hover {
      background: @bg-hover-color;
    }
    &:active {
      box-shadow: 2px 2px 6px rgba(5, 73, 43, 0.20999999344348907);
    }
    &:focus {
      .box-shadow(none);
    }
  }
  * {
    font-size: inherit;
  }
  .icon {
    margin-right: 8px;
  }
  &.disabled {
    background: #F1F1F1;
    border-color: #F1F1F1;
    color: #CCCCCC;
    cursor: not-allowed;
  }
  &.full-width {
    width: 100%;
  }
}
.bt {
  .button-constructor();
  &.inline {
    display: inline-block;
    &:not(button) {
      display: inline-flex;
    }
  }
  &.link {
    border: none;
    background: none;
    min-width: unset;
    padding: 0;
    margin: 0;
    box-shadow: none;
    color: #656f7a;
    line-height: 100%;
    height: auto;
    min-height: initial;
  }
  &.s1 {
    width: 340px;
  }
}
.bt-white {
  .button-constructor (
    @bt-width: 120px,
    @bt-height: 50px,
    @font-size:20px,
    @bg-color: #fff,
    @border-color: @color-0,
    @color: @color-0,
    @bg-hover-color: darken(#fff, 10%),
    @radius: 3px
  );
}
.bt-green {
  .button-constructor (
    @bt-width: 120px,
    @bt-height: 50px,
    @font-size:20px,
    @bg-color: @color-7,
    @border-color: @color-7,
    @color: #fff,
    @bg-hover-color: darken(@color-7, 10%),
    @radius: 3px
  );
}
.buttons-group {
  display: flex;
  .box-shadow(0 1px 3px #d8d8d8);
  border-radius: 4px;
  .bt {
    box-shadow: none;
    &:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    & + .bt {
      margin-left: -1px;
    }
  }
}
a {
  &.bt {
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
}
.buttons-holder {
  display: flex;
  justify-content: space-between;
  & > * + * {
    margin-left: 20px;
  }
  &.inline {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
}
.link {
  color: @color-1;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  &.v2 {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.btn-a {
  font-size: 20px;
  text-align: center;
  padding: 10px 15px;
  color: @color-7;
  border: 2px solid @color-7;
  transition: background-color 0.2s, color 0.2s;
  text-decoration: none;
  display: inline-block;
  min-width: 250px;
  &:hover {
    background: @color-7;
    color: #fff;
  }
}
.btn-quick {
  display: none;
  position: fixed;
  background: #fff;
  padding: 10px;
  width: 100%;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  .bt-white {
    display: block;
    width: 100%;
    max-width:360px;
    margin: 0 auto;
    box-shadow: none;
  }
}
.btn-full {
  width: 100%;
  display: block;
}
