.tabs {
  &.tabs-products {
    .tabs-link{
      font-size: 28px;
      font-weight: 700;
      color: #333333;
      border-bottom: 2px solid transparent;
      transition: border-color 0.2s, color 0.2s;
      padding: 10px 15px;
      &:hover {
        background: transparent;
        color: @color-1;
      }
      &.tabs-link-active {
        color: @color-1;
        background: transparent;
        border-color: @color-1;
      }
    }
    .tabs-list {
      width: 100%;
      border-bottom: 1px solid #979797;
    }
    .tabs-li{
      border: none;
      width: auto;
    }
    .tabs-li-first{
      .tabs-link {
        padding-left: 0;
      }
    }
  }
  &-list {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    margin: 0 0 20px 0;
    padding: 0;
  }
  &-li {
    width: 50%;
    padding: 0;
    list-style: none;
    border: 2px solid @color-1;
    text-align: center;
  }
  &-link {
    padding: 10px;
    font-size: 20px;
    font-weight: 400;
    display: block;
    text-decoration: none;
    background: transparent;
    color: @color-1;
    transition: background-color 0.2s, color 0.2s;
    &:hover {
      background: @color-4;
      color: #fff;
    }
    &.tabs-link-active {
      color: #fff;
      cursor: pointer;
      background: @color-1;
    }
  }
  &-itm {
    display: none;
    &.tabs-itm-active {
      display: block;
    }
  }
}
