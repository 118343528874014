.swiper-container {
  width: 100%;
}
.swiper-slide {
  width: auto;
  max-width: 100%;
  img {
    max-width: 100%;
  }
}
