.heading-caption (@color: @color-0, @size: 36px) {
  font-size: @size;
  font-weight: 600;
  color: @color;
  margin-bottom: 0.7em;
  position: relative;
  font-family: Gotham Rounded, sans-serif;
  margin-top: 0;
}
.heading-caption {
  .heading-caption();
  &.center {
    text-align: center;
  }
  &.no-weight {
    font-weight: 400;
  }
  .font-icons {
    .size(40px);
    border-radius: 1000px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    min-width: 40px;
    &:before {
      margin: 0;
    }
  }
  &.success {
    display: flex;
    align-items: center;
    .font-icons {
      background: @color-7;
      &:before {
        content: '\e800';
      }
    }
  }
  &.error {
    display: flex;
    align-items: center;
    .font-icons {
      background: @color-6;
      &:before {
        content: '\e801';
      }
    }
  }
  &.s2 {
    font-size: 32px;
  }
  &.s3 {
    font-size: 28px;
  }
  &.s4 {
    font-size: 24px;
  }
  &.s5 {
    font-size: 20px;
    font-weight: 500;
  }
  &.s6 {
    font-size: 18px;
    font-weight: 500;
  }
}

