.columns-flex {
  display: flex;
  align-items: stretch;
  &.align-right {
    justify-content: flex-end;
  }
  &.align-between {
    justify-content: space-between;
    align-items: center;
  }
  &.align-center {
    align-items: center;
  }
  & > .column {
    display: inline-block;
    &.l {
      text-align: left;
    }
    &.c {
      text-align: center;
    }
    &.r {
      text-align: right;
    }
    &.column-logo {
      flex-grow: 1;
    }
  }
  &.auto {
    .column {
      width: auto;
    }
  }
  &.top {
    align-items: flex-start;
  }
  &.rows {
    flex-direction: column;

    .column {
      width: 100%;
    }
  }
  &.full-width {
    & > .column {
      flex-grow: 1;
    }
  }
}
.img-holder {
  font-size: 0;
  img {
    max-width: 100%;
  }
}
.notification {
  border-radius: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px;
  min-height: 41px;
  line-height: 1.2;
  margin-bottom: 20px;
  &:before {
    font-family: @icon-font-name;
    font-style: normal;
    font-weight: normal;
    speak: none;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .size(25px);
    font-size: 11px;
    border-radius: 1000px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-right: 8px;
  }
  &.error {
    background: fade(@color-6, 10%);
    color: @color-6;
    &:before {
      content: '\e801';
      background-color: @color-6;
    }
  }
  &.success {
    background: fade(@color-7, 10%);
    color: @color-7;
    &:before {
      content: '\e800';
      background-color: @color-7;
    }
  }
}
.notification-message {
  margin: 15px 0;
  font-weight: 500;
  &.center {
    text-align: center;
  }
  &.v2 {
    color: @color-2;
  }
  &.error {
    color: @color-6;
  }
  &.success {
    color: @color-7;
  }
}
.sitemap {
  padding: 25px 30px;
  background: #fff;
  &-outer {
    max-width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -20px;
  }
  &-itm {
    padding: 0 20px;
  }
  &-title {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  &-list {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 15px;
    }
    a {
      text-decoration: none;
      color: #333333;
      font-size: 16px;
    }
  }
}
.error-message {
  margin-bottom: 15px;
  font-weight: 600;
  color: @color-0;
  font-size: 36px;
  &.center {
    text-align: center;
  }
}
.error-notice {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.error-server {
  max-width: 380px;
  display: block;
  margin: 0 auto 15px;
}
.error-img {
  display: block;
  margin: 0 auto 15px;
  max-width: 275px;
}
.pagination-info {
  font-size: 16px;
  margin-bottom: 10px;
}
.pagination-holder {
  margin-bottom: 20px;
  .pagination {
    display: inline-flex;
    background: #fff;
    a {
      .button-constructor (
        @bt-width: 50px,
        @bt-height: 50px,
        @font-size:18px,
        @bg-color: #fff,
        @border-color: transparent,
        @color:@color-0,
        @bg-hover-color: @color-4,
        @radius: 3px
      );
      box-shadow: none;
      &:not(.disabled) {
        &:hover, &.active {
          color: #fff;
        }
        &.active {
          background: @color-1;
        }
      }
      &.disabled {
        background: #fff;
        color: #E5E5E5;
        cursor: not-allowed;
        border: none;
      }
    }
  }
}
.breadcrumbs-holder {
  margin-bottom: 45px;
  .breadcrumbs {
    font-size: 0;
    .single-item {
      display: inline-flex;
      align-items: center;
      padding: 3px;
      font-size: 14px;
      color: @color-3;
      &:after {
        content: '>';
        margin-left: 6px;
        display: inline-block;
        color: @color-3;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.active {
        color: @color-1;
        &:after {
          color: @color-1;
        }
      }
      a {
        color: @color-1;
      }
    }
  }
}
ul {
  &.custom-list {
    text-align: left;
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      &.success {
        &:before {
          background: @color-7;
        }
      }
      &.error {
        &:before {
          background: @color-6;
        }
      }
      & + li {
        margin-top: 7px;
      }
      &:before {
        content: '';
        .size(10px);
        background: #999999;
        margin-right: 8px;
        border-radius: 1000px;
      }
    }
  }
}
.search-block {
  height: 48px;
  max-width: 326px;
  display: flex;
  align-items: stretch;
  position: relative;
  &.focus {
    .input-block {
      border-color: @color-1;
    }
  }
  .input-block {
    height: 100%;
    border: 2px solid #CCE0EE;
    border-right: none;
    border-radius: 2px 0 0 2px;
    transition: 0.3s ease-out all;
    input {
      border: none;
      background: none;
      border-radius: 0;
      max-width: 100%;
      box-sizing: border-box;
      box-shadow: none !important;
      height: inherit;
      padding: 0 17px;
      font-size: inherit;
      flex-grow: 1;
      color: @color-0;
      outline: none;
      .placeholder(@color-3);
      &:focus, &:active {
        &:not(:disabled) {
          box-shadow: 0 0 7px 3px #eef3fd;
        }
      }
    }
  }
  .bt {
    font-size: 18px;
    width: 48px;
    min-width: auto;
    min-height: auto;
    padding: 0;
    flex-shrink: 0;
    border-radius: 0 2px 2px 0;
    box-shadow: none;
  }
}
.quantity {
  width: 125px;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  &.quantity-limited {
    input {
      background: #F7E5E7;
      color: @color-6;
    }
  }
  .field {
    margin: 0;
    .size(42px);
  }
  .input-block {
    border-width: 1px;
    border-color: #F1F1F1;
    input {
      min-height: auto;
      text-align: center;
      height: auto;
    }
  }
  .bt {
    .size(42px);
    font-size: 32px;
    font-weight: 300;
    min-width: auto;
    min-height: auto;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
}
.text-block {
  line-height: 1.5;
  &.v2 {
    & + .text-block {
      margin-top: 18px;
    }
  }
}
.summary-info {
  padding: 30px;
  background: #CCE0EE;
}
.summary-block {
  background: #fff;
  padding: 30px;
  .message {
    margin: 15px 0;
    font-size: 16px;
    &.error {
      color: @color-6;
    }
  }
  .single-item {
    justify-content: space-between;
    &+.single-item {
      margin-top: 10px;
    }
  }
  .top-part {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #CCE0EE;
  }
  .bottom-part {
    &+.single-item {
      margin-top: 15px;
    }
    .text {
      font-weight: 500;
    }
    .value {
      font-weight: 500;
      color: @color-1;
    }
    .buttons-holder {
      margin-top: 15px;
    }
    .heading-caption {
      margin-bottom: 0;
    }
    .columns-flex {
      margin-bottom: 10px;
    }
  }
}
.blocks-holder {
  .content-block {
    background: #fff;
    padding: 26px 30px;
    &.v2 {
      .line-divider {
        margin-left: -30px;
        margin-right: -30px;
      }
    }
    & + * {
      margin-top: 20px;
    }
    .caption {
      margin: 0 0 20px 0;
      font-size: 28px;
      font-weight: 600;
      &.v2 {
        font-size: 18px;
        margin: 0 0 5px 0;
        font-weight: 500;
      }
    }
    .caption-block {
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
      .caption {
        margin: 0;
      }
      &>.column {
        width: auto;
      }
      .link {
        font-weight: 500;
      }
    }
    .table {
      border-collapse: collapse;
      width: 100%;
      th, td {
        padding: 15px 10px;
        text-align: left;
      }
      th {
        font-weight: 600;
        border-bottom: 2px solid @color-1;
      }
      td {
        border-bottom: 1px solid #E5E5E5;
      }
    }
  }
}
.edit-form {
  display: none;
  max-width: 450px;
  .field {
    display: block;
  }
}
.address-form {
  max-width: 450px;
}
.dropdown {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  &.active {
    .dropdown-content {
      display: block;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 3;
    .arrow {
      position: relative;
      z-index: 2;
      right: 0;
      top: 11px;
      background: #fff;
      .size(20px);
      transform: rotate(45deg);
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.02);
    }
    .close {
      font-size: 15px;
      color: @color-0;
    }
    &>.holder {
      box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
      background: #fff;
      padding: 30px;
      width: 460px;
      position: relative;
      z-index: 1;
    }
  }
}
.line-divider {
  background: @color-8;
  height: 1px;
  box-shadow: none;
  border: none;
  background: #000;
  margin: 15px 0;
}

