.product {
  border-bottom: solid 1px #cccccc;
  max-width: 750px;
  padding: 20px 40px 20px 20px;
  background: #fff;
  position: relative;
  &.product-cart {
    padding: 0 40px 20px 0;
    .trash {
      top: 0;
      right: 0;
    }
  }
  &.product-desc {
    padding: 15px 0;
    .product-img {
      width: 100px;
      height: 100px;
    }
    .product-caption {
      margin-bottom: 10px;
      font-weight: 400;
    }
    .price {
      font-weight: 400;
      font-size: 18px;
    }
    .columns-flex {
      margin: 0 -10px;
    }
    .column {
      padding: 0 10px;
    }
  }
  &+& {
    margin-top: 20px;
  }
  &-caption {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  &-in-stock {
    color: #945c01;
  }
  &-out-of-stock {
    color: @color-6;
  }
  &-status {
    margin-bottom: 10px;
  }
  &-img {
    width: 155px;
    height: 155px;
    object-fit: contain;
  }
  .price {
    font-weight: 500;
    font-size: 22px;
  }
  .trash {
    color: @color-0;
    font-size: 17px;
    text-decoration: none;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: color 0.2s;
    &:hover {
      color: @color-1;
    }
  }
  .right-part {
    flex-grow: 1;
    padding: 0 15px;
    .columns-flex {
      margin: 0 -10px 15px;
      align-items: center;
      .column {
        padding: 0 10px;
        width: auto;
      }
    }
  }
  .bt-white {
    padding: 10px 12px;
    min-height: auto;
  }
}