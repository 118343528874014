.custom-radiobox {
  vertical-align: middle;
  display: flex;
  align-items: center;
  color: @color-0;
  font-family: 'Rubik', sans-serif;
  & + .custom-radiobox {
    margin-top: 10px;
  }
  input[type='radio'] {
    position: absolute;
    left: -999999px;
  }
  label {
    cursor: pointer;
    font-weight: 400;
    line-height: initial;
    margin: 0;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      font-family: @icon-font-name;
      font-style: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #fff;
      .size(25px);
      margin-right: 15px;
      border: 1px solid @color-1;
      background: transparent;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-around;
      .transition(0.3s ease-out all);
      border-radius: 1000px;
    }
  }
  input[type='radio']:checked ~ input[type='hidden'] ~ label, input[type='radio']:checked ~ label {
    &:before {
      content: '\e800';
      font-size: 10px;
      background: @color-1;
    }
  }
  input[type='radio']:disabled {
    font-size: 0;
    background: #8e8e8e;
  }
}