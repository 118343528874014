.authorization {
  background: url("@{image-url}descr.png");
  padding: 50px 0;
  &-top {
    text-align: center;
    margin-bottom: 30px;
    .heading-caption {
      color: #fff;
      margin: 0;
    }
  }
  &-bottom {
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%);
    background: #fff;
    padding: 40px 0;
    max-width: 1000px;
    margin: 0 auto 50px;
    .heading-caption {
      margin-bottom: 25px;
      text-align: center;
    }
  }
  &-cart {
    margin: 0 auto;
    display: block;
    max-width: 465px;
  }
  &-forgot {
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
    .link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .bt {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
  }
}