@media only screen and (max-width: 1024px) {
  .main-content-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .pagination-holder {
    margin: 20px 0;
  }
  .show-filter {
    .filter {
      display: block;
    }
    .items-holder {
      display: none!important;
    }
  }
  .show-menu {
    .items-holder {
      display: block!important;
    }
    .filter {
      display: none;
    }
  }
  .categories-block {
    .items-holder {
      display: none;
    }
    .heading {
      padding: 0;
      &-menu {
        flex-grow: 1;
        position: relative;
        &:after {
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          position: absolute;
          content: '';
          display: block;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #fff;
        }
        &.active {
          &:after {
            border-top: none;
            border-bottom: 8px solid #fff;

          }
        }
      }
      &-filters {
        background: @color-1;
      }
      &-outer {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
      }
      &-icon {
        position: relative;
        width: 19px;
        height: 22px;
        margin-right: 10px;
      }
      &-count {
        top: -5px;
        right: -10px;
        width: 19px;
        height: 11px;
        background: #c2d941;
        position: absolute;
        border-radius: 10px;
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        line-height: 11px;
        display: block;
        color: @color-1;
      }
      &-filter {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 0 15px;
      }
      &-text {
        font-size: 16px;
        color: #fff;
      }
      &-svg {
        fill: #fff;
        width: 20px;
        height: 21px;
      }
      &-itm {
        padding: 15px 10px;
      }
    }
  }
  .filter {
    display: none;
    &-links {
      display: none;
    }
    &-all {
      display: block;
    }
    &-checkbox {
      opacity: 1;
      visibility: visible;
      height: auto;
      overflow: visible;
      margin-bottom: 15px;
    }
  }
  .catalog-page {
    padding-top: 0;
    .container {
      padding: 0;
    }
    .heading-caption {
      display: none;
      &.catalog-caption {
        display: block;
        text-align: left;
      }
    }
  }
  .columns-flex {
    &.main-layout {
      flex-direction: column;
      margin: 0;
      .sidebar {
        padding: 0;
        width: 100%;
        &.v2 {
          width: 100%;
        }
      }
      .main-content {
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }
  .bt-green {
    &.full-width {
      max-width: 220px;
    }
  }
  .swiper-container-free-mode {
    & > .swiper-wrapper {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .breadcrumbs-holder {
    margin-bottom: 20px;
  }
  .breadcrumbs {
    margin-bottom: 20px;
  }
  .card {
    &-outer {
      flex-direction: column;
    }
    &-slider {
      height: 380px;
      margin: 0 auto 20px;
      flex-direction: column-reverse;
    }
    &-price {
      font-size: 24px;
    }
    .gallery {
      width: 100%;
      height: calc(100% - 40px);
    }
    .gallery-thumbs {
      &.swiper-container {
        width: auto;
        text-align: center;
        margin: 0 auto;
        height: auto;
      }
      .swiper-slide {
        width: 20px;
        height: 20px!important;
        background-image: none!important;
        border-radius: 50%;
        border: 1px solid #999999;
        margin: 10px 5px!important;
        &.swiper-slide-thumb-active {
          background-color: #999999;
        }
      }
    }
  }
  .details {
    &-wrap {
      padding: 20px;
    }
    .options {
      width: 300px;
    }
  }
  .single-part {
    flex-direction: column;
  }
  .heading-caption-mobile {
    display: block;
    text-align: left!important;
  }
  .heading-caption-desktop {
    display: none;
  }
  .address-block {
    .single-address {
      width: calc(50% - 30px);
    }
  }
  .heading-caption {
    &.error {
      flex-direction: column;
      .font-icons {
        margin: 0 auto 15px auto;
      }
    }
  }
  .account-info {
    position: relative;
    overflow: hidden;
    display: block;
    border-bottom: 1px solid #CCE0EE;
    margin-bottom: 20px;
    padding-right: 20px;
    .bt-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 5px;
      display: block;
      width: 15px;
      height: 15px;
      transform: rotate(-137deg);
      border-left: 3px solid @color-1;
      border-bottom: 3px solid @color-1;
    }
    .columns-flex {
      flex-wrap: wrap;
    }
    .column {
      margin-bottom: 10px;
    }
    .column-caption {
      width:140px;
      padding-right: 5px;
    }
  }
  .orders-block {
    .account-info {
      border-bottom:none;
    }
  }
  .table-account {
    display: none;
  }
  .dropdown {
    &.dropdown-nav {
      display: none!important;
    }
    &.dropdown-fixed {
      .dropdown-content {
        top: 100%;
        > .holder {
          position: absolute;
          top: 100%;
          height: auto;
        }
      }
    }
    .dropdown-content {
      top:71px;
      & > .holder {
        position: fixed;
        height: calc(100% - 60px);
        width: 100%;
        background: #f8f7f9;
        z-index: 9;
        right: 0;
        top: 71px;
        overflow: auto;
      }
    }
  }
  .column-mobile {
    display: block!important;
    padding: 0;
  }
  .column-desktop {
    display: none!important;
    padding: 0!important;
  }
  body {
    &.overlay {
      overflow: hidden;
    }
  }
  .header-logo {
    width: 120px;
  }
  .search-block {
    height: 35px;
    .bt {
      font-size: 16px;
    }
    .input-block {
      input {
        font-size: 14px!important;
      }
    }
  }
  .menu {
    &.menu-header {
      display: none;
      position: fixed;
      height: calc(100% - 60px);
      width: 100%;
      background: #f8f7f9;
      z-index: 9;
      left: 0;
      top: 70px;
      padding: 20px;
      margin: 0;
      &.open {
        display: block;
      }
      .menu-ttl {
        display: block;
        font-size: 18px;
        text-transform: uppercase;
        color: #585858;
        border-bottom: 1px solid #999999;
        padding-bottom: 20px;
      }
      .menu-item {
        display: block;
        margin: 0;
        padding: 15px 0;
        border-bottom: 1px solid #999999;
        font-size: 16px;
        &.active {
          border-color: #999999;
          color: @color-1;
        }
      }
      .menu-close {
        position: absolute;
        right: 20px;
        top: 25px;
        width: 20px;
        height: 20px;
        display: block;
        &:after {
          transform: rotate(-45deg);
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after,
        &:before {
          position: absolute;
          content: '';
          display: block;
          background: #333333;
          width: 2px;
          border-radius: 5px;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  .header {
    padding: 10px 0;
    &-btn {
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 25px;
      position: relative;
      margin-right: 15px;
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      &:before,
      &:after {
        border-radius: 15px;
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: '';
        display: block;
        background: @color-1;
      }
      span {
        border-radius: 15px;
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        display: block;
        background: @color-1;
      }
    }
    .cart-block {
      .link-cart {
        margin-right: 15px;
      }
    }
  }
  .product-holder-details {
    margin-bottom: 30px;
  }
  .categories-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .balance {
    position: relative;
    max-width: 100%;
    top: 0;
    left: 0;
    padding: 10px;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
    &-count {
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
    }
    &-itm {
      width: auto;
    }
  }
  .column-portal {
    display: block!important;
  }
  .column-order {
    order: 1;
    margin: 20px 0 0 0!important;
  }
  .form-outer-wrap  {
    .form-itm {
      width:33%;
    }
  }
  .blocks-holder-popup {
    height: auto;
  }
  .flex-outer {
    &.flex-outer-form {
      .flex-row {
        padding: 0 40px;
      }
    }
  }
  .step {
    &-itm {
      padding: 0 30px;
      &:before,
      &:after {
        width: 30px;
      }
    }
    &-text {
      font-size: 14px;
    }
    &-icon {
      width: 27px;
      height: 27px;
      font-size: 14px;
      line-height: 25px;
    }
    &-active {
      .step-icon {
        &:after {
          width: 18px;
          height: 18px;
        }
      }
    }
    &-done {
      .step-icon {
        &:after {
          height: 5px;
          width: 11px;
        }
      }
    }
  }
  footer {
    padding: 20px 0;
    .copyrights {
      font-size: 14px;
    }
    .menu-holder {
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 10px;
      .caption {
        margin-bottom: 15px;
      }
      .menu-item {
        padding: 0;
        margin-bottom: 10px;
        display: block;
      }
      .menu-item + .menu-item {
        border-left: none
      }
    }
  }
  .footer {
    &__outer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -10px;
    }
  }
  .list-nav {
    flex-wrap: wrap;
    padding-bottom: 0;
    &-li {
      margin-bottom: 20px;
      padding: 0 5px;
    }
    &-link {
      font-size: 18px;
      padding: 5px 0;
    }
  }
  .tabs {
    &.tabs-products {
      .tabs-link {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
  .catalog {
    padding: 25px 15px 0;
    &-itm {
      width: 33.3%;
    }
  }
  .footer {
    &-list {
      justify-content: center;
      align-items: flex-start;
    }
    &-link {
      padding: 15px 10px 10px;
    }
    &-itm {
      display: none;
      &.footer-itm-link {
        padding-bottom: 15px;
        display: block;
        .copyrights {
          display: block;
        }
      }
    }
  }
  .account-block {
    .dropdown-content {
      .holder {
        width: 100%;
      }
    }
  }
}