.registration {
  &-text {
    margin-bottom: 20px;
  }
  &-wrap {
    max-width: 450px
  }
  &-about {
    margin-bottom: 15px;
  }
  &-info {
    background: #fff;
    max-width: 750px;
    margin: 0 auto;
    padding: 25px 30px;
  }
  &-confirm {
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
  }
  &-desc {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
  }
}