.gradient(@start-color, @mid-color, @color-stop, @end-color) {
  background-image: -webkit-linear-gradient(@start-color, @mid-color @color-stop, @end-color);
  background-image: linear-gradient(@start-color, @mid-color @color-stop, @end-color);
  background-repeat: no-repeat;
}
.gradient(@start-color, @end-color) {
  background-image: -webkit-linear-gradient(@start-color, @end-color);
  background-image: linear-gradient(@start-color, @end-color);
  background-image: -o-linear-gradient(@start-color,  @end-color);
  background-image: -ms-linear-gradient(@start-color,  @end-color);
  background-repeat: no-repeat;
}
.gradient(@start-color, @color-stop, @end-color) {
  background-image: -webkit-linear-gradient(@start-color, @color-stop, @end-color);
  background-image: -o-linear-gradient(@start-color, @color-stop,  @end-color);
  background-image: -ms-linear-gradient(@start-color, @color-stop,  @end-color);
  background-image: linear-gradient(@start-color, @color-stop, @end-color);
  background-repeat: no-repeat;
}
.border-radius(@r) {
  -webkit-border-radius: @r;
  -moz-border-radius: @r;
  border-radius: @r;
}
.border-radius(@r1, @r2, @r3, @r4) {
  border-top-left-radius: @r1;
  border-top-right-radius: @r2;
  border-bottom-right-radius: @r3;
  border-bottom-left-radius: @r4;
}
.box-shadow(@x, @y, @f, @color: inherrit) {
  -webkit-box-shadow: @x @y @f @color;
  -moz-box-shadow: @x @y @f @color;
  box-shadow: @x @y @f @color;
}
.box-shadow(@position, @x, @y, @f, @color) {
  -webkit-box-shadow: @position @x @y @f @color;
  -moz-box-shadow: @position @x @y @f @color;
  box-shadow: @position @x @y @f @color;
}
.box-shadow(@x, @y, @f) {
  -webkit-box-shadow: @x @y @f;
  -moz-box-shadow: @x @y @f;
  box-shadow: @x @y @f;
}
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}
.box-shadow(@shadow1, @shadow2) {
  -webkit-box-shadow: @shadow1, @shadow2;
  -moz-box-shadow: @shadow1, @shadow2;
  box-shadow: @shadow1, @shadow2;
}
.box-shadow2(@x, @y, @f, @radius, @color, @position:inherrit, @x, @y, @f, @radius, @color) {
  -webkit-box-shadow: @x @y @f @radius @color, @position @x @y @f @radius @color;
  -moz-box-shadow: @x @y @f @radius @color, @position @x @y @f @radius @color;
  box-shadow: @x @y @f @radius @color, @position @x @y @f @radius @color;
}
.size(@w, @h) {
  width: @w;
  height: @h;
}
.size(@s) {
  width: @s;
  height: @s;
}
.icon(@path, @w, @h) {
  background-image: url("@{icon-img-path}@{path}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .size(@w, @h);
  display: inline-flex;
  flex-shrink: 0;
}
.icon(@path) {
  @url: @image-url;
  background-image: url("@{url}@{path}.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.icon-svg(@path) {
  @url: @image-url;
  background-image: url("@{url}@{path}.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
}
.icon-svg(@path, @w, @h) {
  @url: @image-url;
  background-image: url("@{url}@{path}.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  .size(@w, @h);
  display: inline-block;
  vertical-align: middle;
}
.icon-before(@path, @w, @h) {
  position: relative;
  padding-left: @w;
  min-height: @h;
  min-width: @w;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -@h/2;
    .icon(@path, @w, @h);
  }
}
.placeholder (@color) {
   &::placeholder {
    color: @color;
    font-weight: 300;
    opacity: 1;
  }
}
.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}
.opacity(@o) {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@o)";

  /* IE 5-7 */
  filter: alpha(opacity=@o);

  /* Netscape */
  -moz-opacity: @o;

  /* Safari 1.x */
  -khtml-opacity: @o;

  /* Good browsers */
  opacity: @o;
}
.saturate(@pct) {
  filter: ~"saturate(@{pct})";
  -webkit-filter: ~"saturate(@{pct})";
  -moz-filter: ~"saturate(@{pct})";
  -o-filter: ~"saturate(@{pct})";
  -ms-filter: ~"saturate(@{pct})";
}
.user-select(@value){
  -webkit-user-select: @value;
  -moz-user-select: @value;
  -ms-user-select: @value;
  -o-user-select: @value;
  user-select: @value;
}

.filter(@filter){
  -webkit-filter: @filter;
  filter: @filter;
}
.animation(@name, @duration, @count, @function, @delay, @state){
  -webkit-animation-name: @name;
  -ms-animation-name: @name;
  animation-name: @name;
  -webkit-animation-duration: @duration;
  -ms-animation-duration: @duration;
  animation-duration: @duration;
  -webkit-animation-iteration-count: @count;
  -ms-animation-iteration-count: @count;
  animation-iteration-count: @count;
  -webkit-animation-timing-function: @function;
  -ms-animation-timing-function: @function;
  animation-timing-function: @function;
  -webkit-animation-delay: @delay;
  -ms-animation-delay: @delay;
  animation-delay: @delay;
  -webkit-animation-play-state: @state;
  -ms-animation-play-state: @state;
  animation-play-state: @state;
}
.reset-text() {
  font-family: @font;
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: normal;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
.translate(@x; @y) {
  -webkit-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y); // IE9 only
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}
.transition-transform(@transition) {
  -webkit-transition: -webkit-transform @transition;
  -moz-transition: -moz-transform @transition;
  -o-transition: -o-transform @transition;
  transition: transform @transition;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.flex-gap(@gap) {
  margin-left: -@gap/2;
  margin-right: -@gap/2;
  &>* {
    padding-left: @gap/2;
    padding-right: @gap/2;
  }
}