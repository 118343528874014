.table-order {
  margin-bottom: 20px;
  &.table-order-cart {
    margin-bottom: 0;
  }
  &-ttl {
    width: 130px;
    padding: 5px 0;
  }
  &-price {
    font-size: 24px;
    font-weight: 700;
  }
  &-info {
    text-align: right;
  }
  td{
    padding: 5px 5px 5px 0;
  }
}