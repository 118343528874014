.card {
  margin-bottom: 60px;
  &-outer {
    display: flex;
    margin: 0 -10px;
  }
  &-itm {
    padding: 0 10px;
  }
  &-slider {
    display: flex;
    flex-shrink: 0;
    align-items: stretch;
    height: 520px;
    width: 545px;
  }
  &-price {
    font-size: 32px;
    font-weight: 600;
    color: @color-1;
    line-height: 100%;
    margin-bottom: 25px;
    &.price-disabled {
      color: #999999;
    }
  }
  &-status {
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: uppercase;
  }
  &-columns {
    margin: 0 -5px 10px;
    .column {
      padding: 0 5px;
    }
  }
  &-info {
    margin-bottom: 25px;
  }
  &-text {
    margin-bottom: 25px;
  }
  &-top {
    margin-bottom: 30px;
  }
  &-bottom {
    padding-top: 25px;
    border-top: 1px solid #979797;
  }
  &-out-of-stock {
    text-transform: uppercase;
    color: @color-6;
    font-weight: 400;
  }
  .quantity {
    margin-bottom: 25px;
  }
  .swiper-slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .bt-prev, .bt-next {
    .size(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: @color-3;
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
  }
  .bt-prev {
    left: 0;
  }
  .bt-next {
    right: 0;
  }
  .gallery {
    flex-shrink: 0;
    width: 425px;
    height: 100%;
    border: 2px solid transparent;
    position: relative;
    &--red {
      border-color: #E26A76;
      .gallery-info {
        background: #E26A76;
      }
    }
    &--blue {
      border-color: #4A7FB2;
      .gallery-info {
        background: #4A7FB2;
      }
    }
    .gallery-info {
      text-align: center;
      box-sizing: border-box;
      display: block;
      font-size: 16px;
      color: #fff;
      padding: 5px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
    }
  }
  .gallery-thumbs {
    height: 100%;
    margin-right: 20px;
    .swiper-slide {
      cursor: pointer;
      width: 100%;
      height: 25%;
      opacity: 0.5;
    }
    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
  &-btn {
    min-width: 50%;
    min-height: auto;
  }
  &-buttons {
    .card-btn + .card-btn{
      margin-top: 20px;
    }
  }
}