.dropdown {
  .dropdown-content > {
    .holder {
      &.account-nav {
        width: 230px;
        padding: 30px 30px 15px;
      }
    }
  }
}
.account-list {
  padding: 0;
  margin: 0;
  text-align: left;
  li {
    margin-bottom: 15px;
    list-style: none;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .link {
    text-decoration: none;
  }
}
.info-message {
  text-decoration: none;
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  background: #9b8ec1;
  display: block;
  padding: 10px 0;
  margin-bottom: 45px;
  .info-wrap {
    position: relative;
    margin: 0 auto;
    max-width: 850px;
    padding: 0 20px;
  }
  .info-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0 20px;
  }
  .info-svg {
    fill: #fff;
    width: 22px;
    height: 20px;
    display: block;
  }
}
.admin-page {
  padding: 0 0 45px 0;
  .blocks-holder {
    height: 100%;
    .content-block {
      height: 100%;
    }
  }
}
.text-color {
  color: @color-1;
}
.list {
  margin: 0;
  padding: 0;
  &-li {
    list-style: none;
    font-size: 18px;
    margin-bottom: 25px;
    position: relative;
    padding-left: 30px;
    line-height: 27px;
    &:before {
      left: 0;
      content: '';
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: @color-1;
      top: 7px;
    }
  }
}
.balance-info {
  color: @color-1;
  &+& {
    margin-top: 25px;
  }
  &-ttl {
    font-size: 16px;
  }
  &-num {
    font-size: 24px;
    white-space: nowrap;
  }
}
.color-red {
  color: @color-6;
}
.color-green {
  color: #146600;
}
.table {
  &-body {
    font-size: 16px;
  }
  &-head {
    font-size: 18px;
    font-weight: 400;
  }
  &-sort {
    color: @color-1;
  }
  &-outer {
    vertical-align: middle;
    display: inline-block;
  }
  &-ttl {
    vertical-align: middle;
    display: inline-block;
    line-height: 18px;
  }
  &-arrow {
    display: block;
    width: 5px;
    height: 5px;
    margin-bottom: 3px;
    border-left: 5px solid transparent;
    border-right:5px solid transparent;
    border-bottom: 5px solid @color-1;
    &.bottom {
      transform: rotate(-180deg);
    }
  }
}
.blocks-holder {
  &.holder-outer {
    margin-top: 20px;
  }
  .content-block {
    .table {
      &.table-transaction {
        th {
          font-weight: 400;
        }
      }
    }
  }
}
.pagination-holder {
  &.pagination-table {
    margin: 30px 0 0;
    text-align: right;
    .pagination {
      display: inline-block;
      border: 1px solid #CCE0EE;
      a {
        border-radius: 0;
      }
    }
  }
}
.form-block {
  max-width: 460px;
}
.form-outer {
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
}
.form-fixed {
  .form-itm {
    width: 25%;
  }
}
.form-outer-flex {
  .form-itm {
    width: 50%;
  }
}
.form-outer-wrap {
  flex-wrap: wrap;
  .form-itm {
    width: 25%;
  }
}
.form-itm {
  padding: 0 10px;
}
.btn-outer {
  margin-bottom: 1em;
}

.notification {
  &.members {
    font-weight: 400;
    padding: 30px;
    background: #CCE0EE;
  }
  &-outer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 -10px;
  }
  &-itm {
    padding: 0 10px;
  }
}
.columns-link {
  margin: 0 -10px;
  justify-content: space-between;
  .column {
    padding: 0 10px;
  }
}
.column-portal {
  display: none!important;
}
.table-sort {
  white-space: nowrap;
}
.total {
  border-bottom: 1px solid #E5E5E5;
  padding: 15px 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
  &-col {
    padding: 0 5px;
  }
}
.brand-color {
  color: @color-4;
}
.blocks-holder-popup {
  height: 400px;
  overflow-y: auto;
  .content-block {
    padding: 0!important;
    margin: 0;
  }
}
.table-body-empty {
  td {
    border-bottom: none!important;
  }
}
.column-name {
  font-size: 20px;
}
.dropdown {
  &.dropdown-menu {
    .dropdown-content {
      width: 100%;
      .holder.account-nav {
        width: 100%;
      }
    }
    .dropdown-link {
      color: #fff;
      text-decoration: none;
    }
    .font-icons {
      margin: 0 5px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
    }
    .account-list {
      color: @color-4;
    }
  }
  &.dropdown-nav {
    margin-left: 50px;
    min-width: 300px;
    &.active {
      background: @color-4;
      .dropdown-link {
        color: #fff;
        flex-grow: 1;
      }
      .dropdown-btn {
        span,
        &:after,
        &:before {
          background: #fff;
        }
      }
    }
    .bt {
      background: transparent;
      border-color: @color-4;
      padding: 12px 50px 12px 12px;
      font-size: 16px;
      text-align: left;
      min-height: auto;
      display: flex;
      justify-content: flex-start;
    }
    .account-list {
      a {
        text-decoration: none;
      }
    }
    .dropdown-link {
      color: @color-4;
      flex-grow: 1;
    }
  }
  &.dropdown-options {
    position: absolute;
    top: 0;
    right: 0;
    &.active {
      .dropdown-wrap {
        background: @color-1;
        border-color: @color-1;
      }
      .dropdown-svg {
        fill: #fff;
      }
    }
    .dropdown-svg {
      width: 20px;
      height: 25px;
      display: block;
      fill: @color-4;
    }
    .dropdown-wrap {
      border-color: #CCE0EE;
      width: 40px;
      padding: 5px;
      min-width: auto;
      box-shadow: none;
    }
    .dropdown-content {
      > .holder {
        background: #fff;
      }
    }
  }
  .dropdown-btn {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 20px;
    position: relative;
    margin-right: 10px;
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    &:before,
    &:after {
      border-radius: 15px;
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      content: '';
      display: block;
      background:  @color-4;
    }
    span {
      border-radius: 15px;
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
      display: block;
      background:  @color-4;
    }
  }
}
.scroll-table-error {
  padding-bottom: 50px;
}
.scroll-table {
  width: 100%;
  overflow-x: auto;
  .table-head {
    white-space: nowrap;
  }
}
.td-wrap {
  white-space: normal;
}

.form-itm-mobile {
  display: none;
}
.link-filter {
  display: none;
}
.edit-input {
  background: none;
  display: inline-block;
  border: 2px solid #CCE0EE;
  width: 90px;
  color: #333333;
  padding: 5px;
  &:disabled {
    border-color: transparent;
  }
}
.btn-options {
  display: none;
  &.show {
    display: flex;
  }
}
.btn-edit {
  &.hide {
    display: none;
  }
}
.bt-small {
  min-width: 90px!important;
}
.edit-info {
  &.change {
    .btn-options {
      display: flex;
    }
    .btn-edit {
      display: none;
    }
  }
}
.td-btn {
  width: 230px;
  text-align: right!important;
}
.pass {
  white-space: nowrap;
  &-icon {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 20px;
    svg {
      fill: #000;
    }
  }
  &-text {
    display: inline-block;
    vertical-align: middle;
  }
}
.table {
  .activate {
    color: #999;
    .pass-icon {
      svg {
        fill: #999;
      }
    }
  }
}
.activate {
  color: #999;
  .pass-icon {
    svg {
      fill: #999;
    }
  }
}
.account-info{
  &.activate {
    color: #999;
  }
}
.notice-error {
  color: @color-6;
  position: relative;
  &-info {
    display: inline-block;
    color: #fff;
    background:@color-6;
    padding: 5px;
    text-transform: uppercase;
  }
  &-close {
    text-decoration: none;
    color: #000;
    position: absolute;
    top: 20px;
    right: 15px;
  }
  &-alert {
    width: auto;
    display: none;
    position: absolute;
    top: 100%;
    background: #fff;
    padding: 20px 40px 15px 15px;
    left: 0;
    color: #000;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
    min-width: 220px;
    white-space: nowrap;
    &:after {
      position: absolute;
      z-index: 2;
      left: 15px;
      top: -8px;
      background: #fff;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.02);
      content: '';
    }
    &.show {
      display: block;
    }
  }
}
.caption-error {
  overflow: hidden;
}