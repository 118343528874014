.filter {
  background: #fff;
  padding: 25px;
  position: sticky;
  top: 0;
  z-index: 2;
  margin-bottom: 20px;
  &.fixed-form {
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.15);
    .filter-wrap {
      margin-bottom: 0;
    }
    .filter-form {
      display: none;
    }
    .filter-link-show-all {
      display: block;
    }
    .filter-link-show {
      display: none;
    }
    .filter-link-hide{
      display: none;
    }
    &.open-form {
      .filter-link-hide{
        display: none;
      }
    }
  }
  &-link-show-all {
    display: none;
  }
  &-link-hide {
    display: none;
  }
  &-all {
    display: none;
  }
  &-wrap {
    margin-bottom: 15px;
  }
  &-checkbox {
    margin: 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: opacity 0.2s, visibility 0.2s;
  }
  .field {
    max-width: 450px;
  }
  .heading-caption {
    margin: 0;
  }
}
.show-filter {
  .filter {
    &.fixed-form{
      .filter-form {
        display: block;
      }
      .filter-wrap {
        margin-bottom: 15px;
      }
      .filter-link-hide {
        display: block;
      }
      .filter-link-show-all {
        display: none;
      }
    }
    &-link-show {
      display: none;
    }
    &-link-hide{
      display: block;
    }
    &-checkbox {
      margin-bottom: 15px;
      display: block;
      opacity: 1;
      visibility: visible;
      height: auto;
      overflow: visible;
    }
    &-all {
      display: block;
    }
  }
}
