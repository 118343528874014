.selectric-lang {
  color: @color-4;
  display: inline-block;
  vertical-align: middle;
  .selectric {
    background: none;
    border: none;
  }
  .label {
    text-transform: uppercase;
    font-size: 18px;
    height: auto;
    line-height: initial;
    margin: 0;
    padding: 0;
    color: @color-4;
  }
  .button {
    display: none;
  }
  .ico {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
  }
  .ico-en {
    background: url("@{icon-url}en.svg") no-repeat;
  }
  .ico-esp {
    background: url("@{icon-url}sp.svg") no-repeat;
  }
  .selectric-items {
    left: auto;
    right: 0;
    width: 220px!important;
    border: none;
    margin-top: 10px;
    &:after {
      position: absolute;
      z-index: 2;
      right: 20px;
      top: -8px;
      background: #fff;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.02);
      content: '';
    }
    ul {
      padding: 15px;
      background: #fff;
    }
    ul,li {
      font-size: 18px;
      color: #333;
    }
    li {
      padding: 8px;
      transition: color 0.2s;
      &.highlighted {
        background: none;
        color: @color-4;
      }
      &:hover {
        color: @color-4;
        background: none;
      }
    }
  }
}