.carousel {
  margin: 30px 0;
  &-outer {
    position: relative;
    padding: 0 80px;
  }
  &-itm {
    height: 100%;
  }
  .owl-item {
    min-height: 100%;
    padding: 0 10px;
  }
  .owl-stage-outer {
    overflow: hidden;
  }
  .owl-stage {
    display: flex;
    align-items: stretch;
  }
  .owl-nav {
    left: 0;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
  .owl-prev {
    left: 0;
    &:after {
      left: 6px;
      transform: rotate(45deg);;
    }
  }
  .owl-next {
    right: 0;
    &:after {
      left: -6px;
      transform: rotate(-135deg);;
    }
  }
  .owl-prev,
  .owl-next {
    position: absolute;
    width: 50px;
    height: 50px;
    border: solid 1px #cccccc;
    background: #ffffff;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      border-left:2px solid #333333;
      border-bottom: 2px solid #333333;
      transition: border-color 0.2s;
    }
    &:focus {
      outline-color: transparent;
    }
    &:hover {
      &:after {
        border-color: @color-1;
      }
    }
  }
  .preview-caption {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }
}