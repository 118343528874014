@media only screen and (max-width: 768px) {
  .header {
    &-logo-link {
      margin: 0 auto;
      transform: translateX(35px);
      display: block;
      width: 100px;
    }
  }
  //sections
  .authorization {
    padding: 0 0 50px;
    background: none;
    &-cart {
      display: none;
    }
    &-bottom {
      padding: 30px 0;
      position: relative;
      margin: -120px auto 0;
      max-width: calc(100% - 30px);
      box-shadow: 3px 4px 20px rgba(0,0,0,0.15);
    }
    &-top {
      background: url("@{image-url}descr.png");
      margin-bottom: 0;
      padding: 30px 15px 0;
      .heading-caption {
        margin-bottom: 20px;
      }
    }
    &-forgot {
      margin-bottom: 15px;
    }
    &-img {
      display: block;
      margin: 0 auto;
      max-width: 340px;
    }
    .form {
      padding-bottom: 30px;
      border-bottom: 1px solid #979797;
    }
    .container {
      padding: 0;
    }
  }
  .description {
    padding: 40px 0 20px;
    background: #ffffff;
    &-img {
      max-width: 340px;
      margin-bottom: 20px;
    }
    &-desktop {
      display: none;
    }
    &-mobile {
      display: block;
    }
  }
  .field{
    label {
      font-size: 16px;
    }
    input {
      font-size: 14px!important;
      height: 40px!important;
    }
    &.v2 {
      .input-block-holder {
        .message {
          left: 0;
          top: 0;
          position: static;
          margin-top: 5px;
          &:before {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
  main {
    padding: 30px 0;
  }
  .pagination-holder {
    .pagination {
      a {
        min-width: 35px;
        min-height: 35px;
        font-size: 14px;
        padding: 5px;
      }
    }
  }
  .heading-caption {
    text-align: center;
    font-size: 26px;
    &.s2 {
      font-size: 22px;
    }
    &.s3 {
      font-size: 24px;
      text-align: left;
    }
    &.success {
      flex-direction: column;
    }
    .font-icons {
      margin: 0 auto 15px auto;
    }
  }
  .form-block {
    .heading-caption {
      text-align: left;
    }
  }
  .form-head {
    min-height: 340px;
    padding-top: 30px;
    position: relative;
  }
  .block-notice {
    .block-notice-wrap {
      padding: 20px;
    }
  }
  body {
    font-size: 16px;
  }
  .bt {
    margin: 0 auto;
    font-size: 16px;
    min-height: auto;
    padding: 10px 15px;
    &.bt-close {
      width: auto!important;
      height: auto!important;
    }
  }
  .notification {
    font-size: 14px;
    &:before {
      width: 20px;
      height: 20px;
      font-size: 7px;
    }
  }
  .bt-white {
    font-size: 16px;
    min-height: auto;
    padding: 10px 15px;
  }
  .buttons-holder {
    &.center {
      flex-direction: column
    }
    & > * + * {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  .notification-message {
    font-size: 14px;
  }
  .summary-info {
    padding: 20px;
  }
  .summary-block {
    padding: 20px;
    .items-holder {
      .single-item {
        .image-block {
          width: 56px;
          height: 56px;
        }
      }
    }
    .heading-caption {
      text-align: left;
    }
  }
  .quantity {
    .field {
      height: 35px;
    }
    input,
    .input-block{
      height: 100%;
    }
    .bt {
      width: 35px;
      height: 35px;
      font-size: 24px;
    }
  }
  .product {
    padding:0;
    .image-block {
      width: 100%;
      height: 100px;
    }
    .price {
      font-size: 18px;
    }
    &-img {
      margin: 0 auto;
      width: 100px;
      height: auto;
      display: block;
    }
    .columns-flex {
      display: block;
    }
    .column {
      display: block;
      margin-bottom: 15px;
    }
    .bt-white {
      width: 100%;
    }
    .right-part {
      padding: 0;
    }
    .trash {
      top: 0;
    }
  }
  .columns-flex {
    &.columns-flex-cart {
      flex-direction: column;
      position: relative;
    }
    &.columns-flex-caption {
      flex-direction: column;
    }
    &.columns-flex-payment {
      flex-direction: column;
      .column {
        margin-bottom: 10px;
      }
    }
    &.columns-flex-details {
      flex-direction: column;
    }
  }
  .bt-green {
    font-size: 16px;
    min-height: auto;
    padding: 10px 15px;
    &.full-width {
      max-width: 100%;
    }
  }
  .custom-radiobox {
    label {
      &:before {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    input[type='radio']:checked ~ input[type='hidden'] ~ label, input[type='radio']:checked ~ label {
      &:before {
        font-size: 7px;
      }
    }
  }
  .popup-window {
    .btn-full {
      width: 100% !important;
      height: auto!important;
    }
    .popup-information {
      white-space: normal;
    }
    .popup-block {
      padding: 20px;
      .close {
        width: 30px;
        height: 30px;
      }
    }
    .heading-caption {
      font-size: 20px;
      text-align: left;
    }
  }
  .card {
    margin-bottom: 30px;
    &-slider {
      width: 100%;
    }
    &-price {
      margin-bottom: 15px;
    }
    &-info {
      margin-bottom: 15px;
    }
    &-text {
      margin-bottom: 15px;
    }
    &-top {
      margin-bottom: 20px;
    }
    &-bottom {
      padding-top: 20px;
    }
  }
  .details {
    .options {
      width: 100%;
    }
    .columns-flex {
      flex-direction: column;
    }
  }
  .blocks-holder {
    .content-block {
      .caption {
        font-size: 22px;
      }
    }
  }
  .edit-form {
    max-width: 100%;
    .form-confirm {
      margin-bottom: 10px;
    }
  }
  .information-block {
    .info-items {
      .single-item {
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
  .single-address {
    width: calc(100% - 30px);
    flex-direction: column;
    padding: 0 0 15px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    .address-links {
      justify-content: space-between;
    }
  }
  .shipping-block {
    .caption {
      margin-bottom: 10px!important;
    }
  }
  .address-block {
    .single-address {
      width: auto;
    }
    .address-holder {
      flex-direction: column;
    }
    .caption {
      margin-bottom: 10px!important;
    }
    .caption-block {
      margin-bottom: 20px!important;
    }
  }
  .shipping-price {
    font-size: 16px;
  }
  .blocks-holder {
    .content-block {
      padding: 20px;
    }
  }
  .product {
    .summary-block {
      .caption {
        max-width: none;
        padding-right: 15px;
      }
    }
  }
  .order {
    margin-bottom: 20px;
  }
  .contacts-info {
    .text {
      font-size: 16px;
    }
  }
  .error-img {
    max-width: 150px;
  }
  .error-message {
    font-size: 24px;
  }
  .sitemap {
    flex-direction: column;
    &-title {
      font-size: 18px;
    }
  }
  .blocks-holder {
    .content-block {
      &.v2 {
        .line-divider {
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }
  }
  .form {
    &.v2 {
      .buttons-holder {
        > * {
          width: 100%;
        }
      }
    }
  }
  .search-reset,
  .search-close{
    display: block;
  }
  .search-mobile {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background: #f8f7f9;
    z-index: 9;
    right: 0;
    top: 0;
    &.open {
      display: block;
    }
    .font-icons{
      padding: 0 5px;
      &:before {
        color: #333333;
      }
    }
    .search-block {
      box-shadow: 3px 4px 20px rgba(0,0,0,0.15);
      max-width: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px;
      width: 100%;
      .input-block {
        flex-grow: 1;
        border: none;
      }
    }
    .search {
      display: none;
    }
    .xdsoft_autocomplete {
      width: 100% !important;
    }
    .xdsoft_autocomplete {
      .xdsoft_autocomplete_dropdown {
        width: 100% !important;
        position: fixed;
      }
    }
  }
  .header-logo {
    width: 100px;
  }
  .menu {
    &.menu-header {
      top: 60px;
    }
  }
  .dropdown {
    .dropdown-content {
      top:60px;
      & > .holder {
        top: 60px;
      }
    }
  }
  .column-text {
    font-size: 14px;
  }
  .header {
    height: 40px;
    &-search {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
    }
  }
  .product-limit {
    .link {
      padding: 0 5px;
    }
  }
  .limit {
   &-ttl {
     width: auto;
   }
  }
  .no-flex-mobile {
    display: block;
    .column {
      display: block;
    }
  }
  .info-message {
    font-size: 20px;
    text-align: center;
    .info-icon {
      position: static;
      display: inline-block;
      top: auto;
      transform: translateY(0);
      right: auto;
      margin: 0 5px;
      vertical-align: middle;
    }
  }
  .form-outer {
    flex-direction: column;
  }
  .no-flex-mobile {
    flex-direction: column;
  }
  .buttons-holder-mobile {
    display: block;
    .bt, .bt-white {
      display: block;
      width: 100%;
      margin: 0 0 10px 0!important;
    }
  }
  .edit-info {
    &.change {
      .btn-options {
        display: block;
        .bt, .bt-white {
          display: block;
          width: 100%;
          margin: 0 0 10px 0!important;
        }
      }
    }
    .btn-edit {
      margin-bottom: 20px;
      width: 100%;
    }
  }
  .notification {
    &.members {
      padding: 15px;
      overflow: hidden;
      .notification-itm{
        margin-bottom: 10px;
        padding: 0;
      }
      .notification-outer {
        display: block;
        margin: 0;
      }
    }
  }
  .form-outer-flex {
    .form-itm {
      width: 100%;
    }
  }
  .form-fixed {
    .form-itm {
      width: 100%;
    }
  }
  .form-outer-wrap {
    .form-itm {
      width: 100%;
    }
  }
  .form-itm-mobile {
    display: flex;
  }
  .form-itm-desktop {
    display: none;
  }
  .form-inner {
    display: none;
    .form-outer-wrap {
      width: 100%;
    }
    .form-outer {
      margin: 0;
    }
    .form-itm {
      padding: 0;
    }
    &.show {
      display: block;
    }
  }
  .link-filter {
    display: inline-block;
    margin-bottom: 10px;
  }
  .caption-block {
    .dropdown {
      &.dropdown-menu {
        display: block;
      }
    }
  }
  .notice-error-alert {
    left: auto;
    right: 0;
    &:after {
      left: auto;
      right: 15px;
    }
  }
  .registration {
    &-text {
     text-align: center;
      margin-bottom: 15px;
    }
    &-form{
      max-width: 400px;
      margin: 0 auto;
    }
    &-info {
      .registration-text {
        text-align: left;
      }
    }
    &-wrap {
      max-width: 400px;
      margin: 0 auto;
      .bt {
        margin: 0 auto;
        display: block;
      }
    }
  }
  .flex-outer {
    &.flex-outer-confirm {
      flex-direction: column;
      .flex-row {
        width: 100%;
      }
    }
    &.flex-outer-desc {
      flex-direction: column;
      .flex-row {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &.flex-outer-reg {
      flex-direction: column;
      position: relative;
      align-items: center;
      .flex-row {
        padding: 0 20px;
        width: 100%;
      }
    }
    &.flex-outer-form {
      flex-direction: column;
      align-items: center;
      .flex-row {
        padding: 0 20px;
        width: 100%;
        text-align: center;
        max-width: 400px;
        &:first-child {
          padding-bottom: 30px;
          border-right: none;
        }
      }
    }
  }
  .step {
    display: none;
  }
  .hide-mobile {
    display: none!important;
  }
  .tabs-link {
    font-size: 16px;
    padding: 10px 15px;
  }
  .quick-close {
    display: block;
    z-index: 2;
  }
  .block-quick {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    max-width: 100%;
    height: 100%;
    .heading-caption {
      text-align: center;
    }
    .text {
      margin-bottom: 20px;
    }
    &.show {
      z-index: 5;
    }
  }
  .btn-quick {
    display: block;
  }
  .footer-reg {
    padding-bottom: 90px;
  }
  .unsubscribe {
    &-info {
      font-size: 28px;
    }
  }
  .selectric-lang {
    .label {
      font-size: 16px;
    }
  }
  .column-logo {
    text-align: center;
  }
  .error-server {
    max-width: 250px;
  }
  .tabs {
    &.tabs-products {
      .tabs-link {
        font-size:18px;
        padding: 10px;
      }
    }
  }
  .table-order {
    &-price {
      font-size: 20px;
    }
  }
  .catalog {
    &-itm {
      width: 50%;
    }
  }
  .carousel {
    &-outer {
      padding: 0 40px;
    }
    .owl-prev,
    .owl-next {
      width: 40px;
      height: 40px;
    }
  }
}