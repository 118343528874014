html {
  min-height: 100%;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  font-family: Gotham Rounded, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  color: @color-0;
  background: #F8F7F9;
  &>* {
    order: 99;
  }
}
.container {
  margin: 0 auto;
  padding: 0 @padding-1;
  width: calc(1140px + @padding-1 * 2);
  max-width: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  &.boxed {
    width: 520px;
  }
}
.block-notice {
  max-width: 750px;
  margin: 0 auto;
  .heading-caption {
    text-align: center;
  }
  .block-notice-wrap {
    background: #fff;
    padding: 25px 30px;
    .heading-caption {
      text-align: left;
    }
  }
}
.account-block {
  .dropdown-content {
    font-family: "Lucida Grande", sans-serif;
    .top-part {
      &>.column {
        &:first-child {
          flex-grow: 1;
          text-align: center;
        }
      }
      justify-content: space-between;
    }
    .holder {
      width: 330px;
    }
    .buttons-holder {
      justify-content: center;
    }
    .bt {
      width: 320px;
      max-width: 100%;
    }
  }
}
header {
  padding: 30px 0 25px;
  background: #fff;
  .container {
    &>.columns-flex {
      align-items: center;
      justify-content: space-between;
      margin: -@padding-1/2;
      &>.column {
        padding: @padding-1/2;
      }
    }
  }
  .menu {
    margin:  0 -15px;
    .menu-close {
      display: none;
    }
    .menu-ttl {
      display: none;
    }
    .menu-item {
      margin: 0 15px;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      color: @color-0;
      &.active, &:hover {
        border-color: @color-1;
      }
    }
  }
  .links {
    align-items: center;
    display: flex;
    position: relative;
    .dropdown {
      position: static;
    }
  }
  .cart-block {
    .link-cart {
      display: inline-flex;
      background: url("@{icon-url}cart.svg")no-repeat center/contain;
      width: 32px;
      height: 30px;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      text-decoration: none;
      margin-right: 25px;
      span {
        margin-bottom: 1px;
        margin-left: 3px;
      }
    }
    .dropdown-content {
      .top-part {
        display: flex;
        justify-content: space-between;
        .items-count {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .items-holder {
        margin-bottom: 20px;
        .single-item {
          padding: 20px 0;
          border-bottom: 2px solid #CCE0EE;
          .image-block {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            flex-shrink: 0;
            .size(76px);
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .price {
            font-weight: 500;
          }
          .quantity {
            font-weight: 500;
            span {
              color: @color-3;
              font-weight: 400;
              margin-right: 5px;
            }
          }
          .trash {
            color: @color-3;
            font-size: 20px;
            &:hover {
              color: @color-0;
            }
          }
          .right-part {
            flex-grow: 1;
            &>.columns-flex {
              margin: -10px;
              justify-content: space-between;
              &>.column {
                padding: 10px;
                width: auto;
              }
            }
          }
        }
      }
      .bottom-part {
        .caption {
          font-weight: 500;
        }
        .price {
          font-size: 20px;
          font-weight: 500;
          color: @color-1;
        }
        .bt-white, .bt-green {
          width: 100%;
        }
        &>.columns-flex {
          justify-content: space-between;
          align-items: center;
          margin: -6px;
          &>.column {
            padding: 6px;
            width: 50%;
            &:last-child {
              text-align: right;
            }
          }
          & + .columns-flex {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .link-user {
    display: inline-flex;
    text-decoration: none;
    background: url("@{icon-url}user.svg")no-repeat center/contain;
    width: 18px;
    height: 26px;
  }
}
p {
  margin: 0;
}
b, strong {
  font-weight: 500;
}
main {
  padding: 45px 0;
  flex-grow: 1;
  &.error-page {
    flex-grow: 0;
  }
}
.columns-flex {
  &.main-layout {
    margin: 0 -10px;
    .sidebar {
      width: 360px;
      flex-shrink: 0;
      padding: 0 10px;
    }
    .main-content {
      flex-grow: 1;
      padding: 0 10px;
    }
  }
}
footer {
  padding: 30px 0;
  background: #fff;
  &.footer-small {
    background: none;
  }
  .menu-holder {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 35px;
    .caption {
      font-size: 20px;
      font-weight: 500;
    }
    .menu-item {
      font-size: 16px;
      padding: 0 20px;
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      & + .menu-item {
        border-left: 1px solid #666666;
      }
    }
  }
  .copyrights {
    color: @color-3;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
}
.footer {
  &.footer-full {
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
  &.footer-catalog {
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    padding: 0;
    .footer-wrap {
      display: none;
    }
    .footer-list {
      display: flex;
    }
  }
  &-show-link {
    display: block;
    height: 100%;
    position: relative;
  }
  &-list {
    display: none;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 -10px;
  }
  &-itm {
    padding: 20px 10px;
    &.footer-itm-link {
      text-align: center;
      padding: 0;
      .copyrights {
        display: none;
      }
    }
  }
  &-link {
    background: #fff;
    display: block;
    height: 100%;
    position: relative;
    z-index: 2;
    padding: 20px 10px;
  }
  &-btn {
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(0,0,0,0.2);
    border-radius: 50%;
    &:after {
      top: 12px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      content: '';
      display: block;
      transform: rotate(135deg);
      border-bottom: 2px solid @color-1;
      border-left: 2px solid @color-1;
      height: 12px;
      width: 12px;
    }
  }
}
